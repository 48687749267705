import React from "react";

const ServicePrice = () => {
  return (
    <>
      <div class="py-8 pricing-5 pricing-section division " id="service-head">
        <div class="container">
          <div class="pricing-5-wrapper">
            <div class="row">
              <div class="col-lg-6">
                <div class="pricing-5-table left-column wow fadeInUp">
                  <div class="pricing-5-category mb-4">
                    <h3 style={{ color: "var(--primary-color" }}>
                      Cutting & Styling
                    </h3>
                  </div>

                  <ul class="pricing-list">
                    <li class="pricing-5-item">
                      <div class="detail-price">
                        <div class="price-name">
                          <p>Women's Haircut</p>
                        </div>
                        <div class="price-dots"></div>
                        <div class="price-number">
                          <p>$153</p>
                        </div>
                      </div>
                    </li>

                    <li class="pricing-5-item">
                      <div class="detail-price">
                        <div class="price-name">
                          <p>Men's Haircut</p>
                        </div>
                        <div class="price-dots"></div>
                        <div class="price-number">
                          <p>$92</p>
                        </div>
                      </div>
                    </li>

                    <li class="pricing-5-item">
                      <div class="detail-price">
                        <div class="price-name">
                          <p>Blow Dry</p>
                        </div>
                        <div class="price-dots"></div>
                        <div class="price-number">
                          <p>$44</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="pricing-5-table right-column wow fadeInUp">
                  <div class="pricing-5-category p5-top mb-4">
                    <h3 style={{ color: "var(--primary-color" }}>
                      Hair Treatments
                    </h3>
                  </div>

                  <ul class="pricing-list">
                    <li class="pricing-5-item">
                      <div class="detail-price">
                        <div class="price-name">
                          <p>Brazilian Blow Out</p>
                        </div>
                        <div class="price-dots"></div>
                        <div class="price-number">
                          <p>$255</p>
                        </div>
                      </div>
                    </li>

                    <li class="pricing-5-item">
                      <div class="detail-price">
                        <div class="price-name">
                          <p>Keratin Complex</p>
                        </div>
                        <div class="price-dots"></div>
                        <div class="price-number">
                          <p>$420</p>
                        </div>
                      </div>
                    </li>

                    <li class="pricing-5-item">
                      <div class="detail-price">
                        <div class="price-name">
                          <p>Permanent Wave</p>
                        </div>
                        <div class="price-dots"></div>
                        <div class="price-number">
                          <p>$240</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicePrice;

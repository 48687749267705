import { Link } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaLevelUpAlt } from "react-icons/fa";
import { FaLocationArrow } from "react-icons/fa";

import { FaMapMarkerAlt } from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <footer className="footer-bg">
        <div
          className="footer-area section-bg pt-4"
          style={{ backgroundImage: "url(images/footer_bg.webp" }}
        >
          <div className="container">
            <div className="footer-top footer-padding">
              <div className="row d-flex justify-content-between">
                <div className="col-xl-3 col-lg-4 col-md-5 col-sm-8">
                  <div className="single-footer-caption mb-50">
                    <div className="footer-logo">
                      <img
                        src="images/salonLogo.webp"
                        style={{ height: "90px" }}
                        alt=""
                      />
                    </div>
                    <div className="footer-tittle">
                      <div className="footer-pera">
                        <p className="info1 text-white ">
                          OUR HAIR STYLE MAKE YOUR LOOK ELEGANCE
                        </p>
                      </div>
                    </div>
                    <div className="footer-number">
                      <h4>
                        <span>+961 </span> 7887 1649
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-5">
                  <div className="single-footer-caption mb-50">
                    <div className="footer-tittle">
                      <h4>Pages </h4>
                      <ul>
                        <li>
                          <Link className="text-white" to="/">
                            Home
                          </Link>
                        </li>
                        <li>
                          <Link className="text-white" to="about">
                            {" "}
                            About
                          </Link>
                        </li>
                        <li>
                          <Link className="text-white" to="service">
                            Services
                          </Link>
                        </li>
                        <li>
                          <Link className="text-white" to="gallery">
                            Gallery
                          </Link>
                        </li>
                        <li>
                          <Link className="text-white" to="contact">
                            Contact
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-8">
                  <div className="single-footer-caption mb-50">
                    <div className="footer-tittle">
                      <h4>Location</h4>
                      <div className="footer-pera">
                        <p className=" text-white ">
                          <FaMapMarkerAlt className="me-1" /> Lebanon
                        </p>

                        <Link style={{ color: "white" }}>
                          <FaLocationArrow className="me-1" /> Get Direction
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="row d-flex justify-content-between align-items-center pt-3">
                <div className="col-xl-9 col-lg-8 col-6">
                  <div className="footer-copy-right">
                    <p className="text-white">
                      Copyright &copy;
                      <script>document.write(new Date().getFullYear());</script>
                      2023 -{" "}
                      <a
                        href="https://abss.tech"
                        target="blank"
                        style={{ color: "var(--secondary-color)" }}
                      >
                        ABSS
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-6 text-end">
                  <div className=" pt-0">
                    <Link>
                      <FaFacebookF className="fs-3 text-white me-4" />
                    </Link>
                    <Link>
                      <FaInstagram className="fs-3 text-white " />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div>
        <a id="back-top" className="text-black" title="Go to Top" href="#">
          {" "}
          <FaLevelUpAlt style={{ color: "var(--secondary-color)" }} />
        </a>
      </div>
    </>
  );
};

export default Footer;

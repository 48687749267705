import React from "react";
import { Link } from "react-router-dom";

const HomeBanner = () => {
  return (
    <>
      <section
        style={{
          background:
            "linear-gradient(to bottom, var(--primary-color), var(--body-color))",
        }}
        id="hero-5"
        className="hero-section pt-lg-5 pt-0"
      >
        {/* <!-- HERO-5 TEXT -->	 */}
        <div className="container mt-lg-5 mt-0">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="hero-5-txt">
                {/* <!-- Logo --> */}
                <div className="hero-logo">
                  <img
                    className="img-fluid"
                    src="images/salonLogo.webp"
                    alt="hero-logo"
                  />
                </div>

                {/* <!-- Title -->	 */}
                <h2>We want to make every girl pretty, happy, and loved!</h2>

                {/* <!-- Text -->	 */}
                <p>Quaerat sodales sapien euismod blandit and primis cubilia</p>

                {/* <!-- Button -->	 */}
                <Link
                  href="#lnk-1"
                  className="btn border border-black btn--tra-primary hover--black"
                >
                  <a href="#waitlist">View Salon Menu</a>
                </Link>
              </div>
            </div>
          </div>
          {/* <!-- End row --> */}
        </div>
        {/* <!-- END HERO-5 TEXT --> */}

        {/* <!-- HERO-5 IMAGES -->	 */}
        <div className="hero-5-img">
          <div className="container-fluid">
            <div className="row">
              {/* <!-- HERO-5 IMAGE -->	 */}
              <div className="col">
                <div id="hero-5-1">
                  <img
                    className="img-fluid"
                    src="images/Headers/hero-5-1.webp"
                    alt="hero-image"
                  />
                </div>
              </div>

              {/* <!-- HERO-5 IMAGE -->	 */}
              <div className="col-md-5">
                <div id="hero-5-2">
                  <img
                    className="img-fluid"
                    src="images/Headers/hero-5-2.webp"
                    alt="hero-image"
                  />
                </div>
              </div>

              {/* <!-- HERO-5 IMAGE -->	 */}
              <div className="col">
                <div id="hero-5-3">
                  <img
                    className="img-fluid"
                    src="images/Headers/hero-5-3.webp"
                    alt="hero-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- END HERO-5 IMAGES -->	 */}
      </section>
    </>
  );
};

export default HomeBanner;

import React from "react";

const teams = [{}, {}, {}, {}];

const OurTeam = () => {
  return (
    <section id="blog-1" class="pt-5 mb-5 blog-section division">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-10 col-lg-8">
            <div class="section-title text-center mb-3">
              <h2 class="h2-title">Meet Our Team</h2>
            </div>
          </div>
        </div>
        <div  class="row">
          {teams.map((team, index) => (
            <div key={index} class="col-md-6 col-lg-3 pb-4">
              <div id="bp-1-1" class="blog-post wow fadeInUp">
                <div class="blog-post-img">
                  <div class="de-image-hover rounded-3">
                    <span className="hover-overlay"></span>
                    <img
                      class="img-fluid rounded-3"
                      src="images/team/team4.webp"
                      alt="blog-post-image" 
                    />
                    <div class="item-overlay"></div>
                  </div>
                </div>
                <div class="blog-post-txt">
                  <h5 class="h5-md post-link text-center pt-4">
                      Team Member Name
                  </h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
export default OurTeam;

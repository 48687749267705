import HeaderSection from "../Component/HeaderSection";
import { FaLocationArrow } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { GrSchedule } from "react-icons/gr";

const Contact = () => {
  return (
    <>
      <HeaderSection name="Contact" />
      <section className="contact-section">
        <div className="container">
          <div>
            <div className="d-none d-sm-block mb-5 pb-4">
              <div
                id="map"
                className="overflow-hidden"
                style={{
                  height: "480px",
                  marginBottom: "70px",
                  position: "relative",
                }}
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26496.124756631863!2d35.483682734698576!3d33.88925221107369!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f17215880a78f%3A0x729182bae99836b4!2sBeirut%2C%20Lebanon!5e0!3m2!1sen!2sbd!4v1698159551657!5m2!1sen!2sbd"
                  style={{ width: "100%", height: "500px", border: "none" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div className="row mt-lg-0">
              <div className="col-12">
                <h2 className="contact-title">Get in Touch</h2>
              </div>
              <div className="col-lg-8">
                <form
                  className="form-contact contact_form"
                  action="contact_process.php"
                  method="post"
                  id="contactForm"
                >
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <textarea
                          className="form-control w-100"
                          name="message"
                          id="message"
                          cols="30"
                          rows="9"
                          placeholder=" Enter Message"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <input
                          className="form-control valid"
                          name="name"
                          id="name"
                          type="text"
                          placeholder="Enter your name"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <input
                          className="form-control valid"
                          name="email"
                          id="email"
                          type="email"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          className="form-control"
                          name="subject"
                          id="subject"
                          type="text"
                          placeholder="Enter Subject"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group mt-3 text-center text-lg-start">
                    <button
                      type="submit"
                      className="btn btn--tra-black hover--black"
                    >
                      Send
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-lg-4 col-12 text-center ">
                <div className="media">
                  <div className="d-flex gap-3">
                    <FaLocationArrow />
                    <div className="media-body text-start">
                      <h5>Location</h5>
                      <p className="mb-0">Lebanon</p>
                    </div>
                  </div>
                </div>
                <div className="media py-3">
                  <div className="d-flex gap-3">
                    <FaPhone />
                    <div className="media-body text-start">
                      <h5>Phone</h5>
                      <p className="mb-0">+961 7887 1649</p>
                    </div>
                  </div>
                </div>
                <div className="media ">
                  <div className="d-flex  gap-3">
                    <GrSchedule />
                    <div className="media-body text-start">
                      <h5>Schedule</h5>
                      <p>Mon to Fri 9am to 6pm</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;

import React from "react";
import { Link } from "react-router-dom";

const images = [
  { img: "images/gallery/gallery1.webp" },
  { img: "images/gallery/gallery2.webp" },
  { img: "images/gallery/gallery3.webp" },
  { img: "images/gallery/gallery1.webp" },
];
const GallerySection = () => {
  return (
    <div
      style={{ backgroundColor: "white" }}
      id="gallery-4"
      className="py-default px-5"
    >
      <div>
        <div className="row justify-content-center">
          <div className="col-md-10 col-lg-8">
            <div className="section-title text-center mb-3">
              <h2 className="h2-title">Gallery</h2>
            </div>
          </div>
        </div>
        <div className="">
          <div className="row g-3">
            {images.map((image, index) => (
              <div key={index} className="col-md-6 col-lg-3 col-12 ">
                <div className="card de-image-hover rounded">
                  <span className="dih-overlay"></span>

                  <img
                    src={image.img}
                    className="card-img-top card-img-bottom"
                    alt="..."
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="row mt-5">
          <div className="col">
            <div className="more-btn">
              <a href="#gallery-head"><Link to="/gallery" className="btn btn--tra-black hover--black">
                Visit Our Gallery
              </Link></a>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GallerySection;

import { useState, useEffect } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import "swiper/css";

function DateAndTime({ setGoSteps }) {
  const [dates, setDates] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const times = [
    "9:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "1:00 PM",
    "2:00 PM",
    "3:00 PM",
    "4:00 PM",
    "5:00 PM",
    "6:00 PM",
    "7:00 PM",
    "8:00 PM",
  ];
  useEffect(() => {
    const generateDateList = () => {
      const today = new Date();
      const twoYearsLater = new Date(today);
      twoYearsLater.setFullYear(today.getFullYear() + 2);

      const dateList = [];
      let currentDate = new Date(today);

      while (currentDate <= twoYearsLater) {
        dateList.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }

      setDates(dateList);

      setSelectedOption(dateList[0].toDateString());
    };

    generateDateList();
  }, []);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setSelectedTime(""); // Reset selected time when a new date is chosen
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const getValidTimeOptions = () => {
    if (!selectedOption) return times;
    const now = new Date();
    const selectedDate = new Date(selectedOption);
    if (
      now.getDate() === selectedDate.getDate() &&
      now.getMonth() === selectedDate.getMonth() &&
      now.getFullYear() === selectedDate.getFullYear()
    ) {
      // The selected date is today, let's filter out times that are in the past
      const nowHours = now.getHours();
      const nowMinutes = now.getMinutes();
      return times.filter((time) => {
        const [timeHours, timeMinutes] = time.split(":");
        return (
          parseInt(timeHours) > nowHours ||
          (parseInt(timeHours) === nowHours &&
            parseInt(timeMinutes) > nowMinutes)
        );
      });
    }
    return times;
  };

  const timeOptions = getValidTimeOptions();

  const formattedSelectedOption = new Date(selectedOption);
  const formattedDate = `${formattedSelectedOption.toLocaleDateString("en-US", {
    weekday: "long",
  })}, ${formattedSelectedOption.toLocaleDateString("en-US", {
    month: "short",
  })} ${formattedSelectedOption.getDate()}`;

  return (
    <>
      <div className="row justify-content-center mx-1">
        <div className="col-md-12 col-lg-8 col-xl-9 col-12 pt-2 custom-border px-2 pb-2 waitlist-box">
          <div className="row">
            <div className="col-2 text-start">
              <AiOutlineArrowLeft
                style={{ background: "var(--primary-color)" }}
                onClick={() => setGoSteps(2)}
                className=" gj-cursor-pointer back-btn fs-3 p-1 rounded"
              ></AiOutlineArrowLeft>
            </div>
            <div className="col-8 text-center pb-3">
              <h2 className=" fw-bold ">Date and Time</h2>{" "}
            </div>
          </div>
          <div className="d-flex position-relative mt-3">
            <AiOutlineArrowLeft className="swiper-button-prev"></AiOutlineArrowLeft>
            <AiOutlineArrowRight className="swiper-button-next"></AiOutlineArrowRight>
          </div>
          <div className="mt-4 mb-3">
            <Swiper
              spaceBetween={10}
              modules={[Navigation]}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              slidesPerView={7}
              onSwiper={(swiper) => console.log(swiper)}
              breakpoints={{
                1024: {
                  slidesPerView: 7,
                },
                768: {
                  slidesPerView: 4,
                },
                320: {
                  slidesPerView: 3,
                },
              }}
            >
              {dates.map((date, index) => {
                let radioID = `date_radio_${index}`;
                return (
                  <SwiperSlide key={index}>
                    <div className="slider__date">
                      <input
                        type="radio"
                        id={radioID}
                        name="date"
                        value={date.toDateString()}
                        defaultChecked={index === 0}
                        onChange={handleOptionChange}
                      />
                      <label
                        htmlFor={radioID}
                        className="d-flex flex-column align-items-center"
                      >
                        <span>
                          {date
                            .toLocaleDateString("en-US", {
                              weekday: "short",
                            })
                            .substring(0, 3)}
                        </span>
                        <span className="fs-3 fw-bold">
                          {date.toLocaleDateString("en-US", {
                            day: "numeric",
                          })}
                        </span>
                        <span>
                          {date
                            .toLocaleDateString("en-US", {
                              month: "short",
                            })
                            .substring(0, 3)}
                        </span>
                      </label>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className="mobile-view">
            <div>
              <p className="fw-bold">{formattedDate}</p>
            </div>
            <div className="time__container" >
              {selectedOption &&
                timeOptions.map((time, index) => {
                  let radioID = `time_radio_${index}`;
                  return (
                    <div className="time__wrapp" key={index}>
                      <input
                        type="radio"
                        id={radioID}
                        name="time"
                        value={time}
                        onChange={handleTimeChange}
                        checked={time === selectedTime}
                      />
                      <label htmlFor={radioID}>
                        <span>{time}</span>
                      </label>
                    </div>
                  );
                })}
            </div>
          </div>
          <div id="submit" className="mt-1 text-center">
            <button
              onClick={() => setGoSteps(4)}
              className="btn btn--tra-black hover--black"
            >
              Next
            </button>
          </div>
          <div className="d-deco"></div>
        </div>
      </div>
    </>
  );
}

export default DateAndTime;
